<template>
  <div class="paperSharing" v-loading="loading">
    <!-- 论文分享列表 -->
    <list
      :discoveryList="discoveryList"
      :page="page"
      :page-size="pageSize"
    ></list>

    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        hide-on-single-page
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import list from "@components/common/list";

export default {
  components: {
    list,
  },

  data() {
    return {
      loading: true,
      discoveryList: [],
      page: 1,
      pageSize: 10,
      total: 10,
    };
  },

  mounted() {
    this.findcreationListByType(this.page);
  },

  methods: {
    // 获取论文分享列表
    async findcreationListByType(page) {
      let { code, data, message } = await this.$findApi.findcreationListByType({
        type: "11",
        page,
      });
      this.loading = false;
      if (code == 200) {
        this.discoveryList = data.data;
        this.pageSize = data.per_page;
        this.total = data.total;
        this.page = +data.current_page;
      } else {
        this.$message.error(message);
      }
    },

    // 点击换页
    handleCurrentChange() {
      this.xin.isLoadding(true);
      this.findcreationListByType(this.page);
      this.xin.isLoadding(false);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scope>
@import "@assets/css/themeColor.scss";

.paperSharing {
  padding-top: 30px;
  width: 890px;
  margin: 0 auto;

  .pagination {
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>